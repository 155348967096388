import 'assets/styles/app.scss';
import 'foundation-sites';
import AOS from 'aos';
import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', async () => {
	// if ('scrollRestoration' in history) {
	// 	history.scrollRestoration = 'manual';
	// }

	//Load header and footer
	const loadedCoreComponents = [];
	const loadCoreComponents = ['header', 'footer'];
	for (const coreComponent of loadCoreComponents) {
		if (loadedCoreComponents.indexOf(coreComponent) === -1) {
			loadedCoreComponents.push(coreComponent);

			try {
				let component = await import(
					/* WebpackMode: "lazy" */
					/* webpackPrefetch: true */
					/* webpackPreload: true */
					`components/${coreComponent}/${coreComponent}.js`
				);

				component.default();
			} catch (error) {
				console.error('Loading error: ', error);
			}
		}
	}

	const loadedComponents = { 'header': '', 'footer': '' };

	/* Globally call foundation
		*/
	$(document).foundation();


	/**
	 * Bootstrap the components
	 */
	for (const dataComponent of document.querySelectorAll('[data-component]')) {
		let componentName = dataComponent.dataset.component;

		if (!loadedComponents.hasOwnProperty(componentName)) {

			try {
				let component = await import(
					/* WebpackMode: "lazy" */
					/* webpackPrefetch: true */
					/* webpackPreload: true */
					`components/${componentName}/${componentName}.js`
				);

				loadedComponents[componentName] = component;
				component.default();
			} catch (error) {
				console.error('Loading error: ', error);
			}
		} else {
			if (componentName !== 'header' && componentName !== 'footer') {
				let component = loadedComponents[componentName];

				component.default();
			}
		}
	}

	if (document.querySelector('.home-animation') !== null) {
		//Home animation, Do not touch!
		if (sessionStorage.getItem('ani-done') !== null) {
			document.querySelector('.home-animation').classList.add('hidden');
			AOS.init({
				once: true,
				duration: 1000,
			});
			return;
		}

		let tl = gsap.timeline({
			onComplete: function () {
				startReverse();
			}
		});
		let arrLetters = document.querySelectorAll('.home-animation .text-container');
		arrLetters.forEach((e, i) => {
			// let ani = i > 0 ? ">-0.1" : '';
			tl.fromTo(e, { y: 300, opacity: 0, duration: 1}, {y: 0, opacity: 1, duration: 1});
		});
		tl.play();

		function startReverse() {
			let tlTwo = gsap.timeline({
				onComplete: function () {
					let item = document.querySelector('.home-animation')
					gsap.to(item, { height: 0, opacity: 0 });
					AOS.init({
						once: true,
						duration: 1000,
					});
					// sessionStorage.setItem('ani-done', 1);
					document.querySelector('.home-animation').classList.add('hidden');
					AOS.init({
						once: true,
						duration: 1000,
					});
				}
			});
			let arrReverseLetters = [...arrLetters].reverse();
			arrReverseLetters.forEach((e, i) => {
				// let ani = i > 0 ? ">-0.1" : '';
				tlTwo.to(e, { y: -200, opacity: 0, duration: 1 });
			});
			tlTwo.play();
		}
	}

	AOS.init({
		once: true,
		duration: 1000,
	});


	//Check autoplay video's in Chrome.
	document.querySelectorAll('video').forEach(video => {
		if (video.paused) {
			video.play();
		}
	});

	//Scroll down buttons
	if (document.querySelectorAll('.js-scroll-down').length > 0) {
		document.querySelectorAll('.js-scroll-down').forEach((button) => {
			button.addEventListener('click', function (event) {
				event.preventDefault();
				let nextSection = $(this).parents('section').next('section');

				if (nextSection.length > 0) {
					nextSection[0].scrollIntoView({
						behavior: 'smooth',
					});
				}
			});
		});
	}


	const lenis = new Lenis({
		duration: 1.2,
		easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
		direction: 'vertical', // vertical, horizontal
		gestureDirection: 'vertical', // vertical, horizontal, both
		smooth: true,
		mouseMultiplier: 1,
		smoothTouch: false,
		touchMultiplier: 2,
		infinite: false,
	})



	lenis.on('scroll', ({ scroll, limit, velocity, direction, progress }) => {
		// console.log({ scroll, limit, velocity, direction, progress })
	})

	function raf(time) {
		lenis.raf(time)
		requestAnimationFrame(raf)
	}

	requestAnimationFrame(raf)

});

